.SiteSearch__formContainer {
  & .SiteSearch__action {
    & .SiteSearch__submit.SiteSearch__submit {
      @mixin solidColor var(--grayscaleBlackLighten10), var(--grayscaleWhiteBase), var(--grayscaleBlackLighten20);

      & .SvgIcon {
        @mixin strokeIconColor var(--grayscaleWhiteBase);
      }
    }
  }
}
