.Select {
  & .Select__control {
    &.Select__control--menu-is-open {
      & .Select__indicatorIcon {
        fill: var(--grayscaleBlackLighten20);
      }
    }
  }

  & .Select__indicatorIcon {
    fill: var(--grayscaleBlackLighten10);
  }
}
