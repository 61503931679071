.SectionMultiQuoteBlock {
  & .slick-dots {
    bottom: 60px;

    & li {
      & button::before {
        content: "";
      }

      &.slick-active button::before {
        background-color: var(--primaryBase);
        border: 1px solid var(--primaryBase);
      }
    }
  }
}
