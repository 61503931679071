.ProductCoursesSection {
  & .ProductCoursesSection__content {
    & .ProductCoursesSection__title {
      @mixin h4;

      @media (--tablet) {
        @mixin h3;
        margin-bottom: 16px;
      }
    }

    & .ProductCoursesSection__description {
      @media (--tablet) {
        margin: 0 0 24px;
      }
    }
  }
}
