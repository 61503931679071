.Login {
  & .Login__inner {
    & .Login__imageContainer {
      @media (--tablet) {
        align-self: center;
      }

      & .Login__image {
        object-fit: contain;
        object-position: center;
      }
    }

    & .Login__info {
      & .Login__logInButton {
        @mixin solidColor var(--grayscaleBlackLighten10), var(--grayscaleWhiteBase), var(--grayscaleBlackLighten20);
      }
    }
  }
}
