.Video {
  & .Video__title {
    @mixin titleUnderline;
    @mixin h2;
    margin-top: 0;

    @media (--tablet) {
      @mixin h1;
    }
  }
}
