.Header {
  & .Header__link {
    color: var(--courseTextColor, var(--grayscaleBlackLighten10));
    transition: background-color 0.2s ease-out, color 0.1s linear;

    &:hover {
      color: var(--courseTextColor, var(--primaryDarken40));
    }

    &.Header__link--active {
      background-color: var(--courseHoverColor, var(--support01Lighten20));
    }
  }

  & .Header__iconWrapperStyling.Header__iconWrapperStyling {
    @mixin strokeIconColor var(--courseTextColor, var(--primaryBase));

    &.Header__iconWrapperStyling--active {
      background-color: var(--courseHoverColor, var(--support01Lighten20));
    }
  }

  & .Header__mobileMenuButton.Header__mobileMenuButton {
    display: inline-block;

    @media (--tablet) {
      display: none;
    }
  }

  &.Header--activeCourse,
  &.Header--sticky {
    background-color: var(--courseColor, var(--support01Lighten60));

    & .Header__content {
      border-bottom: none;
    }
  }
}
