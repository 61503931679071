.HamburgerMenu {
  & .HamburgerMenu__panel {
    background-color: var(--courseColor, var(--primaryBase));

    & .HamburgerMenu__searchLinkWrapper {
      border-bottom-color: var(--courseTextColor, var(--grayscaleWhiteBase));
    }

    & .HamburgerMenu__searchIcon {
      @mixin strokeIconColor var(--courseTextColor, var(--grayscaleWhiteBase));
    }

    & .HamburgerMenu__searchInput {
      color: var(--courseTextColor, var(--grayscaleWhiteBase));
    }
  }

  & .HamburgerMenu__closeButton.HamburgerMenu__closeButton {
    @mixin solidColor var(--courseColor, var(--primaryBase)), var(--courseTextColor, var(--grayscaleWhiteBase)),
      var(--courseHoverColor, var(--primaryLighten20));
  }

  & .HamburgerMenu__link.HamburgerMenu__link,
  & .HamburgerMenu__secondaryLink.HamburgerMenu__secondaryLink {
    color: var(--courseTextColor, var(--grayscaleWhiteBase));

    &:hover {
      color: var(--courseTextColor, var(--primaryLighten60));
    }

    &.HamburgerMenu__link--active,
    &.HamburgerMenu__secondaryLink--active {
      background-color: var(--courseHoverColor, var(--primaryLighten20));
    }
  }

  & .HamburgerMenu__studiesType {
    color: var(--courseHoverColor, var(--primaryLighten40));
  }
}
