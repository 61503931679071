.LessonHeading {
  display: block;
  border-bottom: none;

  @media (--tablet) {
    margin-bottom: 32px;
  }

  & .LessonHeading__stepsCount {
    display: none;
  }

  & .LessonHeading__generalInfo {
    & .LessonHeading__link {
      @mixin b3;
      display: inline-flex;

      & .LessonHeading__linkIcon {
        fill: var(--grayscaleBlackLighten10);
      }
    }
  }
}
