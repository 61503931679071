.ArticleBuySection {
  & .ArticleBuySection__content {
    &.ArticleBuySection__content--wide {
      @media (--tablet) {
        @mixin lost-column 8/12;
      }

      @media (--desktop) {
        @mixin lost-column 6/12;
      }
    }
  }

  & .ArticleBuySection__imageWrapper {
    &.ArticleBuySection__imageWrapper--default {
      display: none;
    }
  }
}
