.SectionQuoteBlockBase {
  & .SectionQuoteBlockBase__inner {
    background-color: var(--support01Lighten20);
  }

  & .SectionQuoteBlockBase__imageContainer {
    & .SectionQuoteBlockBase__authorContainer {
      background-color: var(--grayscaleWhiteBase);

      & .SectionQuoteBlockBase__apostropheContainer {
        background-color: var(--primaryBase);
      }
    }
  }

  & .SectionQuoteBlockBase__quote {
    @media (--desktop) {
      padding: 85px 0 120px;
    }

    & blockquote {
      font-weight: 400;
    }
  }
}
