.Footer {
  background-color: var(--courseColor, var(--primaryBase));
  color: var(--courseTextColor, var(--grayscaleWhiteBase));

  & .Footer__middleSection {
    & .Footer__middleSectionSplitLine {
      @media (--desktop) {
        @mixin splitLine auto, 64px, var(--courseTextColor, var(--primaryLighten40));
        display: block;
      }
    }

    & .Footer__feedbackSection {
      & .Footer__feedbackButton {
        color: var(--courseTextColor, var(--grayscaleWhiteBase));
        border-color: var(--courseTextColor, var(--grayscaleWhiteBase));
        margin: 24px auto;
      }
    }
  }

  & .Footer__singleSectionDesktopLinkWrapper,
  & .Footer__singleSectionLinkWrapper {
    & .Footer__singleSectionLink {
      color: var(--courseTextColor, var(--grayscaleWhiteBase));
      transition: color 250ms;
      padding: 8px 24px 8px 0;

      &:hover {
        color: var(--courseHoverColor, var(--primaryLighten40));
      }
    }
  }

  & .Footer__bottomSection {
    & .Footer__copyrightText,
    & .Footer__privacyPolicyArticle {
      @mixin p40;
      color: var(--courseTextColor, var(--grayscaleWhiteBase));
    }

    & .Footer__privacyPolicyArticle {
      &:hover {
        color: var(--courseHoverColor, var(--primaryLighten40));
      }
    }

    & .Footer__bottomSplitLine {
      @mixin splitLine 16px, 8px, var(--courseTextColor, var(--grayscaleWhiteBase));
    }
  }

  & .Footer__gyldendalLogo {
    fill: var(--courseTextColor, var(--grayscaleWhiteBase));
  }
}
