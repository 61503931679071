.LogoSection {
  & .LogoSection__splitLine {
    @mixin splitLine 16px, 8px, var(--courseTextColor, var(--grayscaleBlackLighten50));

    @media (--tablet) {
      @mixin splitLine 24px, 16px, var(--courseTextColor, var(--grayscaleBlackLighten50));
    }
  }

  & .LogoSection__gyldendalLogoMobile {
    fill: var(--courseTextColor, var(--primaryBase));
  }

  & .LogoSection__gyldendalLogoDesktop {
    & .LogoSvg__tree {
      fill: var(--courseTextColor, var(--primaryBase));
    }

    & .LogoSvg__text {
      fill: var(--courseTextColor, var(--grayscaleBlackBase));
    }
  }

  & .LogoSection__gyldendalLogoWrapper--white {
    & .LogoSvg__tree {
      fill: var(--courseTextColor, var(--grayscaleWhiteBase));
    }

    & .LogoSvg__text {
      fill: var(--courseTextColor, var(--grayscaleWhiteBase));
    }

    & .LogoSection__gyldendalLogoMobile {
      fill: var(--courseTextColor, var(--grayscaleWhiteBase));
    }
  }
}
