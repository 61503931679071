.Anchors {
  & .Anchors__list {
    & li {
      & button {
        & .Anchors__anchorTitle {
          color: var(--grayscaleBlackLighten10);
        }

        & .Anchors__anchorIcon {
          fill: var(--grayscaleBlackLighten10);
        }
      }
    }
  }
}
