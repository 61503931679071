.FooterListItem.FooterListItem {
  color: var(--courseTextColor, var(--grayscaleWhiteBase));
  transition: background-color 0.2s ease-out, color 0.1s linear;

  &:hover,
  &:active {
    color: var(--grayscaleBlackLighten30);

    @media (--tablet) {
      color: var(--courseTextColor, var(--primaryLighten40));
      background-color: var(--courseHoverColor, transparent);
    }
  }
}
